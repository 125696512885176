<template>
	<secondary-page-layout :title="$t('changeTariff')" :info-link="true">
		<div class="change-tariff form-group" v-if="!showVerify">
			<div>
		        <input-field
		            :label="$t('phoneNumber')"
		            v-model="phone_number"
		           	field-type="tel"
		            :is-block="true"
		            rules="required|numeric|min:10"
		            name="phone"
		            mask="+7(###) ###-##-##"
		        />
		    </div>
		    <div>
		        <input-field
		          :label="personName"
		          :is-block="true"
		          name="iin"
		          field-type="text"
		          rules="required|min:12"
		          :value="iin"
		        	mask="############"
		          class-list="person-iin"
		      />
		    </div>
		 
	        

	        <template v-if="loadingActive">
              <div class="image-card card py-4 px-3" v-for="item in 1" :key="item" style="margin-bottom: 16px;">
                <div class="">
                  <h3 class="title skeleton skeleton-text w-75"></h3>
                  <h3 class="title skeleton skeleton-text w-50"></h3>
                  <h3 class="title skeleton skeleton-text w-25"></h3>
                  <div class="gray-btn skeleton w-50"></div>
                </div>
              </div>
            </template>
         
        	<select-tariff v-else :tariffs="tariffs" :title="$t('rate')" @change_input="changeTariff"></select-tariff>
        	 <!--   <select-rate
		      :title="'Тарифы'"
		      :rates="rates_current"
		      v-else
		      @change_input="changeRate"/> -->

        	<div class="list-agreement--wrap">
        		<p class="title">{{$t('infoAboutInsuredProperty')}}</p>
        		<ul class="list-agreement" v-if="agreement">
					<template v-for="(item, index) in agreement.objects" v-if="agreement.objects">
						<!-- Номера машин-->
						
						<template v-if='"2225" == agreement.insrType'>
							<li class="item">
			          			<span class="title">Под защитой</span>
								<span class="info">{{ item.license_plate }} {{ item.model }} {{ item.title }}</span>
			          		</li>
			          	</template>

						<!--Адрес-->
						<template v-if='agreement.insrType == "3016" || "3014" == agreement.insrType'>
							<li class="item">
			          			<span class="title">Под защитой</span>
								<span class="info">{{ item.street_name}} {{ item.blockNumber }}, {{ item.apartmentNumber }}</span>
			          		</li>
			          	</template>
					</template>
					<li class="item">
						<span class="title">{{ $t('startRate') }} </span>
						<span class="info">{{beginDate}} </span>
					</li>
					<li class="item">
						<span class="title">{{ $t('subscription') }}</span>
						<span class="info">“{{agreement.productName}}”</span>
					</li>
				</ul>
        		
        	</div>
    	

        	<div class="final-amount-wrapper">
        		<div class="final-amount bg-not">
        			<button @click="submitOtp" class="primary-btn">
				      {{$t('changeTariff')}}
				    </button>
        		</div>
        	</div>
	    </div>
      <otp-verify v-else v-model="code" @submit="verifyCode" @sendCode="sendConfirmCode" :error-message="errorMessage"></otp-verify>
	</secondary-page-layout>
</template>
<script>
	import SelectTariff from "@/components/BuyPolicy/SelectTariff";
	import PersonInfo from "@/components/PersonInfo";
	import InputField from "@/components/BuyPolicy/InputField";
	import ChangeTariffModel from "@/components/Modals/ChangeTariffModel";
	import OtpVerify from "@/components/OTP/OtpVerify";
	import {generateUnsubscribeOtp, verifyUnsubscribeOtp} from "@/api/otp";
	import {mapActions, mapGetters} from "vuex";
	import SelectRate from "@/components/BuyPolicy/SelectRate";
	import moment from "moment";

	export default {
		name: "ChangeTariff",
		components: {
			PersonInfo,
			InputField,
			ChangeTariffModel,
			SelectTariff,
			SelectRate,
			OtpVerify
		},
		computed: {
			...mapGetters({
		    	authUser: 'authUser',
		    	itemSub: 'itemSubscription/itemSub',
		    	holder: 'itemSubscription/itemSub',	
		    	phoneNumber: 'itemSubscription/phoneNumber',
          paramOtp: 'itemSubscription/paramOtp'
		    }),
		    argumentId() {
		      return this.$route.params.id || null;
		    },
		    beginDate() {
		    	if(this.agreement){
		    		return moment(this.agreement.beginDate.date).format("DD.MM.YYYY");
		    	}else{
		    		return '';
		    	}
		    },
		    endDate() {
		    	if(this.agreement){
		    		return  moment(this.agreement.endDate.date).format("DD.MM.YYYY");
		    	}else{
		    		return '';
		    	}
		    },
		},
		data() {
		    return {
          phone_number: null,
          clientIin: null,
          loadingActive: true,
          currentTariff: 'Легкая',
          iin: '',
          errorMessage: null,
          code: '',
          tariffs: [],
          agreement: null,
          rates_current: null,
          personName: '',
          showVerify:false,
          data_success:{
            title: this.$i18n.t('tariffChanged_1'),
            subtitle: this.$i18n.t('tariffChanged_2'),
          },
		    }
		},

		async mounted() {
		   	await this.setDefaultInfo();
		   	if(this.agreement.insrType == "2225"){
		   		this.getTariffCoscoSubscription();
		   	}else if(this.agreement.insrType == "3014"){
		   		this.getTariffApartmentSubscription();
		   	}else{
		   		this.getTariffHouseSubscription();
		   	}

       this.changeParamOtp('renewal');
		},
		methods: {
			...mapActions({
		      changePhoneNumber: 'itemSubscription/changePhoneNumber',
		      changeHolder: 'itemSubscription/changeHolder',
		      changeInsureds: 'itemSubscription/changeInsureds',
		      changeManId: 'itemSubscription/changeManId',
		      changeItemSub: 'itemSubscription/changeItemSub',
          changeParamOtp: 'itemSubscription/changeParamOtp'
		    }),
		    submitOtp(){
		    	this.showVerify = true;
		    	this.sendConfirmCode();
		    },
		    async sendConfirmCode() {
				  //generate Otp
		      const loader = this.$loading.show();
		      
		      try {
            await generateUnsubscribeOtp(this.paramOtp, this.$route.params.agreement);
		      } catch (e) {
		        throw e
		      } finally {
		        loader.hide();
		      }
		    },
			async verifyCode(code) {
				//check Otp
				let loader = this.$loading.show();
		      	try {
		        	await verifyUnsubscribeOtp(this.paramOtp, this.$route.params.agreement, code);
		        	await this.onSubmit();
		      	} catch (err) {
			        const { data, status } = err.response
			        if (status === 400) {
			          this.errorMessage = data.message;
			        }
		      	} finally {
		        	loader.hide()
		      	}
		    },
		    async setDefaultInfo(){
				  const loader = this.$loading.show();
				  const {data} = await window.axios.get(`policies/${this.$route.params.agreement}`);
		      	this.agreement = data;
		      	this.phone_number = this.agreement.holder.contacts.MOBILE;
		      	this.personName = this.agreement.holder.title;
		      	this.iin = this.agreement.holder.bin;
		      	
		      	if(this.agreement.insrType == '2225'){
		      		this.rates_current = this.rates_cosco;
		      	}else if(this.agreement.insrType == '3016'){
		      		this.rates_current = this.rates_house;
		      	}else{
		      		this.rates_current = this.rates_flat;
		      	}
		      	this.loadingActive = false
		      	loader.hide();
			},
			
			async onSubmit() {
			    let loader = this.$loading.show({});
				  try {
			       	const { data, message } = await window.axios.post('/subscription/update-tariff', {
			       		policy_id: this.$route.params.agreement,
    					tariff: this.currentTariff
			       	});

			       	this.data_success.subtitle = this.$i18n.t('tariffChanged_2') + ' ' + moment(this.agreement.endDate.date).format("DD.MM.YYYY");
			        loader.hide();

					    this.$router.push({name: 'agreement.verify', params: { data_success: this.data_success} });

			        //this.amount = amount;
			        // let id = 'c69c9475-d34e-4462-866c-cd8a51497212';
			        // await this.$router.push({
              //    	name: 'insurance.products.checkout',
              //    	params: {
              //      		id: id
              //    	},
              //  	});
			        
			    } catch (err) {

			        this.onError({
			          title: `Ошибка`,
			          message: (err?.response?.data?.message || err?.message),
			          code: err?.response?.data?.error_code || 0
			        })

			        loader.hide();
			    }
			},
		    openModal() {
		      this.$modal.show(ChangeTariffModel, { info: 'ss' }, this.$modalConfig)
		    },
		    
		    async getTariffCoscoSubscription() {
		     	try {
		        const {data} = await window.axios.get('/subscription/casco-tariffs');
		       	data.sort(function(a, b) {
		            return parseFloat(a.premMonth) - parseFloat(b.premMonth);
		        });
		        this.tariffs = data;
		      } catch (e) {
		        throw e
		      }finally {
		      	this.loadingActive = false
		      }
		    },
		    async getTariffApartmentSubscription() {
		    	try {
			        const {data} = await window.axios.get('/subscription/apartment-tariffs');
			       
			        data.sort(function(a, b) {
			            return parseFloat(a.premMonth) - parseFloat(b.premMonth);
			        });
			        this.tariffs = data;
		      	} catch (e) {
		        	throw e
		      	}finally {
		      		this.loadingActive = false
		      	}
		    },
		    async getTariffHouseSubscription() {
		    	
		      	try {
		      		const {data} = await window.axios.get('/subscription/house-tariffs');
			        
			        data.sort(function(a, b) {
			            return parseFloat(a.premMonth) - parseFloat(b.premMonth);
			        });
			        this.tariffs = data;
		      	} catch (e) {
		        	throw e
		      	}finally {
		      		this.loadingActive = false
		      	}
		    },

		    changeTariff(index, title){
		    	let name = title;

		    	if(name == 'LIGHT'){
		    		this.currentTariff = 'Легкая';
			    }else if(name == 'STANDARD'){
			    	this.currentTariff = 'Стандартная';
			    }else{
			    	this.currentTariff = 'Оптимальная';
			    }
		    },
		    changeRate(index){
		      if(index == 0){
		        this.currentTariff = 'Легкая';
		      }else if(index == 1){
		         this.currentTariff = 'Стандартная';
		      }else{
		        this.currentTariff = 'Оптимальная';
		      }
		      console.log(this.currentTariff);
		     
		    }, 
		}
	}
</script>